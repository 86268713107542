<template>
  <div class="container my-5">
    <div class="row d-flex justify-content-center">
      <div class="col col-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            New dashboard
          </div>
          <div class="card-body w-100 pt-5">
            <DashboardForm
              :brands="companies"
              :templates="dashboardTypes"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import DashboardForm from './DashboardForm';

export default {
  components: {
    DashboardForm
  },
  computed: {
    ...mapGetters({
      dashboardTypes: 'dashboard/dashboardTypes',
      companies: 'dashboard/companyItems'
    }),
    acceptedExtensions() {
      return '.png';
    }
  },
  async mounted() {
    await this.fetchAllDashboardTypes();
    await this.fetchCompanyItems();
  },
  methods: {
    ...mapActions({
      fetchAllDashboardTypes: 'dashboard/fetchAllDashboardTypes',
      fetchCompanyItems: 'dashboard/fetchCompanyItems'
    })
  }
};
</script>
