<template>
  <div class="d-flex justify-content-center align-items-center m-3">
    <div
      v-if="uploading"
      class="position-absolute d-flex align-items-center w-100 justify-content-center h-100 spinner-background "
    >
      <Spinner />
    </div>
    <div class="dashboard-container">
      <form>
        <TextInput
          v-model="dashboardName"
          :errors="v$.dashboardName.$errors"
          label="Dashboard name"
          inputType="text"
          :readonly="!!updatedName"
          @blur="v$.dashboardName.$touch"
        />
        <SelectInput
          v-model="brandCode"
          :errors="v$.brandCode.$errors"
          :options="brandNameOptions"
          description="Choose brand"
          label="Brand name"
          @blur="v$.brandCode.$touch"
        />
        <SelectInput
          v-model="dashboardId"
          :errors="v$.dashboardId.$errors"
          :options="dashboardTypesOptions"
          description="Choose one dashboard template"
          label="Dashboard template"
          @blur="v$.dashboardId.$touch"
        />
        <SelectInput
          v-model="databaseType"
          :errors="v$.databaseType.$errors"
          :options="databaseTypeOptions"
          description="Choose one database"
          label="Database"
          @blur="v$.databaseType.$touch"
        />
        <FileUploadInput
          ref="file-logo-input"
          description="Put your logo here"
          :acceptedExtensions="acceptedExtensions"
          :errors="inputFileErrors"
          @blur="validateInputFile"
        >
          <template #label-html>
            Brand logo(<span class="text-info">.png</span>) file (optional)
          </template>
        </FileUploadInput>
        <div class="d-flex justify-content-center pt-3">
          <button
            class="btn btn-success"
            type="submit"
            @click.prevent="create"
          >
            Create dashboard
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/common/TextInput';
import { mapActions } from 'vuex';
import _ from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import SelectInput from '@/components/common/SelectInput';
import FileUploadInput from '@/components/common/FileUploadInput';
import { databaseTypes } from '@/components/constants';
import Spinner from '@/components/common/Spinner';

const MAX_FILE_SIZE = 1024 * 1024; //1 Mb
export default {
  components: {
    TextInput,
    SelectInput,
    FileUploadInput,
    Spinner
  },
  props: [ 'brands', 'templates', 'updatedProjectId', 'updatedName' ],
  emits: ['successSave'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      dashboardName: null,
      brandCode: null,
      dashboardId: null,
      databaseType: null,
      projectId: '3d430867-d0b2-4a0e-a797-f016da243c98', //TODO make select for projectId, when will created destination folders
      inputFileErrors: [],
      uploading: false
    };
  },
  validations() {
    return {
      dashboardName: { required: required },
      brandCode: { required: required },
      dashboardId: { required: required },
      databaseType: { required: required }
    };
  },
  computed: {
    brandNameOptions() {
      return _.map(this.brands, (item) => ({ key: item.code, value: item.name }));
    },
    dashboardTypesOptions() {
      return _.map(this.templates, (type) => ({ key: type.id, value: type.name }));
    },
    databaseTypeOptions() {
      return _.map(databaseTypes, (type) => ({ key: type, value: type }));
    },
    acceptedExtensions() {
      return '.png';
    }
  },
  watch: {
    updatedName(newValue) {
      this.dashboardName = newValue;
    },
    brands(newValue) {
      this.brandCode = newValue[0].code;
    },
    templates(newValue) {
      this.dashboardId = newValue[0]?.id;
    },
    updatedProjectId(newValue) {
      this.projectId = newValue;
    }
  },
  methods: {
    ...mapActions({
      createDashboard: 'dashboard/createDashboard'
    }),
    getBrandIdByCode(){
      return this.brands.find((brand) => brand.code === this.brandCode).id;
    },
    getTemplateNameById() {
      const foundObject = this.templates.find((obj) => obj.id === this.dashboardId);
      return foundObject.name;
    },
    getTemplateVersion() {
      const templateName = this.getTemplateNameById();
      const lastSpaceIndex = templateName.lastIndexOf(' ');
      return lastSpaceIndex !== -1 ? templateName.substring(lastSpaceIndex) : 'Unknown version';
    },
    async create() {
      const isFormCorrect = await this.v$.$validate();
      const file = this.$refs['file-logo-input'].getFile();
      if (file != null){
        this.validateInputFile(file);
      }
      if (!isFormCorrect || this.inputFileErrors.length > 0) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      try {
        this.uploading = true;
        const result = await this.createDashboard({
          dashboardName: this.dashboardName + this.getTemplateVersion(),
          brandCode: this.brandCode,
          brandId: this.getBrandIdByCode(),
          dashboardId: this.dashboardId,
          projectId: this.projectId,
          databaseType: this.databaseType,
          brandLogo: file
        });
        const dashboardToSave = {
          brandCode: this.brandCode,
          dashboardName: this.dashboardName + this.getTemplateVersion(),
          templateId: this.dashboardId,
          defaultViewId: result['defaultViewId'],
          dashboardLink: result['webpageUrl'],
          dashboardContentUrl: result['contentUrl']
        };
        this.$emit('successSave', dashboardToSave);
        this.$toast.success('Dashboard created successfully.');
        this.$refs['file-logo-input'].reset();
        this.inputFileErrors = [];
        this.dashboardName = null;
        this.brandCode = null;
        this.dashboardId = null;
        this.databaseType = null;

        this.v$.dashboardName.$reset();
        this.v$.brandCode.$reset();
        this.v$.dashboardId.$reset();
        this.v$.databaseType.$reset();
      } catch (e) {
        const defaultMessage = 'Failed to create dashboard. Please contact support.';
        const errorMessage = _.get(e, 'cause.errorDetails.message');
        this.$toast.error(errorMessage || defaultMessage);
      }
      this.uploading = false;
    },
    validateInputFile(file) {
      this.inputFileErrors = [];

      if (!file) {
        this.inputFileErrors.push({
          $uid: 'file-is-required-error',
          $message: 'The file is required'
        });
        return;
      }

      const fileExtension = _.last(file.name.split('.'));
      if (this.acceptedExtensions.indexOf(fileExtension) === -1) {
        this.inputFileErrors.push({
          $uid: 'file-extension-is-not-allowed-error',
          $message: 'The file extension is not allowed'
        });
      }

      const fileSize = file.size;
      if (fileSize > MAX_FILE_SIZE) {
        this.inputFileErrors.push({
          $uid: 'file-is-too-big',
          $message: 'The file is too big'
        });
      }
    },
  }
};
</script>
<style scoped>
.dashboard-container {
  min-height: 40vh;
}

.spinner-background {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 20;
}
</style>
